import React from 'react';
import './Turyaproduct.css';
import NavBar from '../../Navbar/Navbar';
import { Link } from 'react-router-dom';
import bedroom1 from './product-pages/bedroom1.jpg';
import bedroom2 from './product-pages/bedroom2.jpg';
import bedroom3 from './product-pages/bedroom3.JPG';
import bedroom4 from './product-pages/bedroom4.jpg';
import bedroom5 from './product-pages/bedroom5.jpg';
import bedroom6 from './product-pages/bedroom6.jpg';
import bedroom7 from './product-pages/bedroom7.jpg';

const Bedroom = () => {
  return (
    <>
    <NavBar className="custom-navbars" />
    <div className="container-fluid turyaproduct">
      <div className="row turya-second-row justify-content-between">
        <div className="col-12 col-sm-12 col-md-3 turya-menu-col-1">
          <h5>TURYA DESIGNS</h5>
          <ul>
            <li>HIGHLIGHTS</li>
            <li><Link className='menu-links' to="/Turya">All</Link></li>
                    <li><Link className='menu-links' to="/seating">SEATING</Link></li>
                    <li><Link className='menu-links' to="/Bedroom">BEDROOM</Link></li>
                    <li><Link className='menu-links' to="/Dress">DRESS</Link></li>
                    <li><Link className='menu-links' to="/Mirrors">MIRRORS</Link></li>
                    <li><Link className='menu-links' to="/sidetable">SIDE TABLES</Link></li>
                    <li><Link className='menu-links' to="/Centretable">CENTRE TABLES</Link></li>
                    <li><Link className='menu-links' to="/Dinningtable">DINING TABLES</Link></li>
                    <li><Link className='menu-links' to="/Pa">PARTITIONS<span className='specialchar'>&</span> ART</Link></li>
                    <li><Link className='menu-links' to="/Drawer">CHEST OF DRAWERS</Link></li>
                    <li><Link className='menu-links' to="/Accessories">ACCESSORIES</Link></li>
          </ul>
        </div>
        <div className="col-12 col-sm-12 col-md-4 align-self-end turya-product-head">
           BEDROOM
        </div>
      </div> 
      <div className="row product-grid mt-4">
        <div className="col-12 col-sm-12 col-md-6">
          <Link to="/Bedroom1"><img src={bedroom1} className="img-fluid product-image " alt="Mirror 1"/></Link>
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-md-6">
          <div className="row inner-row-product">
            <div className="col-6">
           
            <Link to="/Bedroom2"><img src={bedroom2} className="img-fluid product-image " alt="Mirror 1"/></Link>
            </div>
            <div className="col-6 ">
            <Link to="/Bedroom3"><img src={bedroom3} className="img-fluid product-image " alt="Mirror 1"/></Link>
            </div>
          </div>
          <div className="row">
            <div className="col-">
            <Link to="/Bedroom4"><img src={bedroom4} className="img-fluid product-image " alt="Mirror 1"/></Link>
            </div>
          </div>
        </div>
        </div>
        <div className="row product-grid">
          <div className="col-12 col-sm-12 col-md-3">
          <Link to="/Bedroom5"><img src={bedroom6} className="img-fluid product-image " alt="Mirror 1"/></Link>
          </div>
          <div className="col-12 col-sm-12 col-md-6">
          <Link to="/Bedroom6"><img src={bedroom7} className="img-fluid product-image " alt="Mirror 1"/></Link>
          </div>  
        </div>
    </div>
    </>
  );
};

export default Bedroom;
