import React from 'react';
import './Turyaproduct.css';
import NavBar from '../../Navbar/Navbar';
import { Link } from 'react-router-dom';
import centre1 from './product-pages/centre1.jpg';
import centre2 from './product-pages/centre2.jpg';
import centre3 from './product-pages/centre3.jpg';
import centre4 from './product-pages/centre4.jpg';
import centre5 from './product-pages/centre5.jpg';
import centre6 from './product-pages/centre6.jpg';
import centre7 from './product-pages/centre7.jpg';
import centre8 from './product-pages/centre8.jpg';
import centre9 from './product-pages/centre9.jpg';
import centre10 from './product-pages/centre10.jpg';
import centre11 from './product-pages/centre11.jpg';
import centre12 from './product-pages/centre12.jpg';
import centre13 from './product-pages/centre13.jpg';
import centre14 from './product-pages/centre14.jpg';
import centre15 from './product-pages/centre15.jpg';
import centre16 from './product-pages/centre16.jpg';
import centre17 from './product-pages/centre17.jpg';
import centre18 from './product-pages/centre18.jpg';
import centre19 from './product-pages/centre19.jpg';
import centre20 from './product-pages/centre20.jpg';
import centre21 from './product-pages/centre21.jpg';
import centre22 from './product-pages/centre22.jpg';
import centre23 from './product-pages/centre23.jpg';
import centre24 from './product-pages/centre24.jpg';
import centre25 from './product-pages/centre25.jpg';
import centre26 from './product-pages/centre26.jpg';
import centre27 from './product-pages/centre27.jpg';
import centre28 from './product-pages/centre28.jpg';
import centre29 from './product-pages/centre29.jpg';
import centre30 from './product-pages/centre30.jpg';
import centre31 from './product-pages/centre31.jpg';
import centre32 from './product-pages/centre32.jpg';
import centre33 from './product-pages/centre33.jpg';
import centre34 from './product-pages/centre34.jpg';
import centre35 from './product-pages/centre35.jpg';
import centre36 from './product-pages/centre36.jpg';
import centre37 from './product-pages/centre37.jpg';
import centre38 from './product-pages/centre38.jpg';
import centre39 from './product-pages/centre39.jpg';
import centre40 from './product-pages/centre40.jpg';
import centre41 from './product-pages/centre41.jpg';
import centre42 from './product-pages/centre42.jpg';
import centre43 from './product-pages/centre43.jpg';
import centre44 from './product-pages/centre44.jpg';
import centre45 from './product-pages/centre45.jpg';
import centre46 from './product-pages/centre46.jpg';
import centre47 from './product-pages/centre47.jpg';
import centre48 from './product-pages/centre48.jpg';
import centre49 from './product-pages/centre49.jpg';
import centre50 from './product-pages/centre50.jpg';
import centre51 from './product-pages/centre51.jpg';
import centre52 from './product-pages/centre52.jpg';
import centre53 from './product-pages/centre53.jpg';
import centre54 from './product-pages/centre54.jpg';
import centre55 from './product-pages/centre55.jpg';
import centre56 from './product-pages/centre56.jpg';
import centre57 from './product-pages/centre57.jpg';


const Centretable = () => {
  return (
    <>
    <NavBar className="custom-navbars" />
    <div className="container-fluid turyaproduct">
      <div className="row turya-second-row justify-content-between">
        <div className="col-12 col-sm-12 col-md-3 turya-menu-col-1">
          <h5>TURYA DESIGNS</h5>
          <ul>
          <li>HIGHLIGHTS</li>
          <li><Link className='menu-links' to="/Turya">All</Link></li>
                    <li><Link className='menu-links' to="/seating">SEATING</Link></li>
                    <li><Link className='menu-links' to="/Bedroom">BEDROOM</Link></li>
                    <li><Link className='menu-links' to="/Dress">DRESS</Link></li>
                    <li><Link className='menu-links' to="/Mirrors">MIRRORS</Link></li>
                    <li><Link className='menu-links' to="/sidetable">SIDE TABLES</Link></li>
                    <li><Link className='menu-links' to="/Centretable">CENTRE TABLES</Link></li>
                    <li><Link className='menu-links' to="/Dinningtable">DINING TABLES</Link></li>
                    <li><Link className='menu-links' to="/Pa">PARTITIONS<span className='specialchar'>&</span> ART</Link></li>
                    <li><Link className='menu-links' to="/Drawer">CHEST OF DRAWERS</Link></li>
                    <li><Link className='menu-links' to="/Accessories">ACCESSORIES</Link></li>
          </ul>
        </div>
        <div className="col-12 col-sm-12 col-md-4 align-self-end turya-product-head">
           CENTRE TABLE
        </div>
      </div> 
      <div className="row product-grid mt-4">
        <div className="col-12 col-sm-12 col-md-6">
          <Link to="/CentreTabledetail1"><img src={centre1} className="img-fluid product-image " alt="Mirror 1"/></Link>
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-md-6">
          <div className="row inner-row-product">
            <div className="col-6">
           
            <Link to="/CentreTabledetail2"><img src={centre2} className="img-fluid product-image " alt="Mirror 1"/></Link>
            </div>
            <div className="col-6 ">
            <Link to="/CentreTabledetail3"><img src={centre3} className="img-fluid product-image " alt="Mirror 1"/></Link>
            </div>
          </div>
          <div className="row">
            <div className="col-">
            <Link to="/CentreTabledetail4"><img src={centre4} className="img-fluid product-image " alt="Mirror 1"/></Link>
            </div>
          </div>
        </div>
        </div>
        <div className="row product-grid">
          <div className="col-12 col-sm-12 col-md-3">
          <Link to="/CentreTabledetail5"><img src={centre5} className="img-fluid product-image " alt="Mirror 1"/></Link>
          </div>
          <div className="col-12 col-sm-12 col-md-6">
          <Link to="/CentreTabledetail6"><img src={centre6} className="img-fluid product-image " alt="Mirror 1"/></Link>
          </div>
          <div className="col-12 col-sm-12 col-md-3">
          <Link to="/CentreTabledetail7"><img src={centre7} className="img-fluid product-image " alt="Mirror 1"/></Link>
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12">
              <Link to="/CentreTabledetail8"><img src={centre8} className="img-fluid product-image " alt="Mirror 1"/></Link>
              </div>
            </div>
          </div>
        </div>
        <div className="row product-grid">
          <div className="col-12 col-sm-12 col-md-6">
          <Link to="/CentreTabledetail9"><img src={centre9} className="img-fluid product-image " alt="Mirror 1"/></Link>
          </div>
          <div className="col-12 col-sm-12 col-md-6">
          <Link to="/CentreTabledetail10"><img src={centre10} className="img-fluid product-image " alt="Mirror 1"/></Link>
          </div>
        </div>
        <div className="row product-grid">
          <div className="col-12 col-sm-12 col-md-6">
          <Link to="/CentreTabledetail11"><img src={centre11} className="img-fluid product-image " alt="Mirror 1"/></Link>
          </div>
          <div className="col-12 col-sm-12 col-md-6">
          <Link to="/CentreTabledetail12"><img src={centre12} className="img-fluid product-image " alt="Mirror 1"/></Link>
          </div>
        </div>
        <div className="row product-grid mt-4">
        <div className="col-12 col-sm-12 col-md-6">
          <Link to="/CentreTabledetail13"><img src={centre13} className="img-fluid product-image " alt="Mirror 1"/></Link>
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-md-6">
          <div className="row inner-row-product">
            <div className="col-6">
           
            <Link to="/CentreTabledetail14"><img src={centre14} className="img-fluid product-image " alt="Mirror 1"/></Link>
            </div>
            <div className="col-6 ">
            <Link to="/CentreTabledetail15"><img src={centre15} className="img-fluid product-image " alt="Mirror 1"/></Link>
            </div>
          </div>
          <div className="row">
            <div className="col-">
            <Link to="/CentreTabledetail16"><img src={centre16} className="img-fluid product-image " alt="Mirror 1"/></Link>
            </div>
          </div>
        </div>
        </div>
        <div className="row product-grid">
          <div className="col-12 col-sm-12 col-md-3">
          <Link to="/CentreTabledetail17"><img src={centre17} className="img-fluid product-image " alt="Mirror 1"/></Link>
          </div>
          <div className="col-12 col-sm-12 col-md-6">
          <Link to="/CentreTabledetail18"><img src={centre18} className="img-fluid product-image " alt="Mirror 1"/></Link>
          </div>
          <div className="col-12 col-sm-12 col-md-3">
          <Link to="/CentreTabledetail19"><img src={centre19} className="img-fluid product-image " alt="Mirror 1"/></Link>
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12">
              <Link to="/CentreTabledetail20"><img src={centre20} className="img-fluid product-image " alt="Mirror 1"/></Link>
              </div>
            </div>
          </div>
        </div>
        </div>
        <div className="row product-grid">
          <div className="col-12 col-sm-12 col-md-3">
          <Link to="/CentreTabledetail21"><img src={centre21} className="img-fluid product-image " alt="Mirror 1"/></Link>
          </div>
          <div className="col-12 col-sm-12 col-md-6">
          <Link to="/CentreTabledetail22"><img src={centre22} className="img-fluid product-image " alt="Mirror 1"/></Link>
          </div>
          <div className="col-12 col-sm-12 col-md-3">
          <Link to="/CentreTabledetail23"><img src={centre23} className="img-fluid product-image " alt="Mirror 1"/></Link>
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12">
              <Link to="/CentreTabledetail24"><img src={centre24} className="img-fluid product-image " alt="Mirror 1"/></Link>
              </div>
            </div>
          </div>
        </div>
        <div className="row product-grid">
          <div className="col-12 col-sm-12 col-md-3">
          <Link to="/CentreTabledetail25"><img src={centre25} className="img-fluid product-image " alt="Mirror 1"/></Link>
          </div>
          <div className="col-12 col-sm-12 col-md-6">
          <Link to="/CentreTabledetail26"><img src={centre26} className="img-fluid product-image " alt="Mirror 1"/></Link>
          </div>
          <div className="col-12 col-sm-12 col-md-3">
          <Link to="/CentreTabledetail27"><img src={centre27} className="img-fluid product-image " alt="Mirror 1"/></Link>
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12">
              <Link to="/CentreTabledetail28"><img src={centre28} className="img-fluid product-image " alt="Mirror 1"/></Link>
              </div>
            </div>
          </div>
        </div>
        <div className="row product-grid">
          <div className="col-12 col-sm-12 col-md-6">
          <Link to="/CentreTabledetail29"><img src={centre29} className="img-fluid product-image " alt="Mirror 1"/></Link>
          </div>
          <div className="col-12 col-sm-12 col-md-6">
          <Link to="/CentreTabledetail30"><img src={centre30} className="img-fluid product-image " alt="Mirror 1"/></Link>
          </div>
        </div>
        <div className="row product-grid">
          <div className="col-12 col-sm-12 col-md-6">
          <Link to="/CentreTabledetail31"><img src={centre31} className="img-fluid product-image " alt="Mirror 1"/></Link>
          </div>
          <div className="col-12 col-sm-12 col-md-6">
          <Link to="/CentreTabledetail32"><img src={centre32} className="img-fluid product-image " alt="Mirror 1"/></Link>
          </div>
        </div>
        <div className="row product-grid">
          <div className="col-12 col-sm-12 col-md-3">
          <Link to="/CentreTabledetail33"><img src={centre33} className="img-fluid product-image " alt="Mirror 1"/></Link>
          </div>
          <div className="col-12 col-sm-12 col-md-6">
          <Link to="/CentreTabledetail34"><img src={centre34} className="img-fluid product-image " alt="Mirror 1"/></Link>
          </div>
          <div className="col-12 col-sm-12 col-md-3">
          <Link to="/CentreTabledetail35"><img src={centre35} className="img-fluid product-image " alt="Mirror 1"/></Link>
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12">
              <Link to="/CentreTabledetail36"><img src={centre36} className="img-fluid product-image " alt="Mirror 1"/></Link>
              </div>
            </div>
          </div>
        </div>
        <div className="row product-grid">
          <div className="col-12 col-sm-12 col-md-6">
          <Link to="/CentreTabledetail37"><img src={centre37} className="img-fluid product-image " alt="Mirror 1"/></Link>
          </div>
          <div className="col-12 col-sm-12 col-md-6">
          <Link to="/CentreTabledetail38"><img src={centre38} className="img-fluid product-image " alt="Mirror 1"/></Link>
          </div>
        </div><div className="row product-grid mt-4">
        <div className="col-12 col-sm-12 col-md-6">
          <Link to="/CentreTabledetail39"><img src={centre39} className="img-fluid product-image " alt="Mirror 1"/></Link>
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-md-6">
          <div className="row inner-row-product">
            <div className="col-6">
            <Link to="/CentreTabledetail40"><img src={centre40} className="img-fluid product-image " alt="Mirror 1"/></Link>

            </div>
            <div className="col-6 ">
            <Link to="/CentreTabledetail41"><img src={centre41} className="img-fluid product-image " alt="Mirror 1"/></Link>
            </div>
          </div>
       
        </div>
        </div>
        <div className="row product-grid mt-4">
        <div className="col-12 col-sm-12 col-md-6">
          <Link to="/CentreTabledetail42"><img src={centre42} className="img-fluid product-image " alt="Mirror 1"/></Link>
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-md-6">
          <div className="row inner-row-product">
            <div className="col-6">
           
            <Link to="/CentreTabledetail43"><img src={centre43} className="img-fluid product-image " alt="Mirror 1"/></Link>
            </div>
            <div className="col-6 ">
            <Link to="/CentreTabledetail44"><img src={centre44} className="img-fluid product-image " alt="Mirror 1"/></Link>
            </div>
          </div>
          <div className="row">
            <div className="col-">
            <Link to="/CentreTabledetail45"><img src={centre45} className="img-fluid product-image " alt="Mirror 1"/></Link>
            </div>
          </div>
        </div>
        </div>
        <div className="row product-grid">
          <div className="col-12 col-sm-12 col-md-3">
          <Link to="/CentreTabledetail46"><img src={centre46} className="img-fluid product-image " alt="Mirror 1"/></Link>
          </div>
          <div className="col-12 col-sm-12 col-md-6">
          <Link to="/CentreTabledetail47"><img src={centre47} className="img-fluid product-image " alt="Mirror 1"/></Link>
          </div>
          <div className="col-12 col-sm-12 col-md-3">
          <Link to="/CentreTabledetail48"><img src={centre48} className="img-fluid product-image " alt="Mirror 1"/></Link>
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12">
              <Link to="/CentreTabledetail49"><img src={centre49} className="img-fluid product-image " alt="Mirror 1"/></Link>
              </div>
            </div>
          </div>
        </div>
        <div className="row product-grid">
          <div className="col-12 col-sm-12 col-md-6">
          <Link to="/CentreTabledetail50"><img src={centre50} className="img-fluid product-image " alt="Mirror 1"/></Link>
          </div>
          <div className="col-12 col-sm-12 col-md-6">
          <Link to="/CentreTabledetail51"><img src={centre51} className="img-fluid product-image " alt="Mirror 1"/></Link>
          </div>
        </div>
        <div className="row product-grid mt-4">
        <div className="col-12 col-sm-12 col-md-6">
          <Link to="/CentreTabledetail52"><img src={centre52} className="img-fluid product-image " alt="Mirror 1"/></Link>
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-md-6">
          <div className="row inner-row-product">
            <div className="col-6">
           
            <Link to="/CentreTabledetail53"><img src={centre53} className="img-fluid product-image " alt="Mirror 1"/></Link>
            </div>
            <div className="col-6 ">
            <Link to="/CentreTabledetail54"><img src={centre54} className="img-fluid product-image " alt="Mirror 1"/></Link>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
            <Link to="/CentreTabledetail55"><img src={centre55} className="img-fluid product-image " alt="Mirror 1"/></Link>
            </div>
          </div>
        </div>
        </div>
        <div className="row product-grid">
          <div className="col-12 col-sm-12 col-md-6">
          <Link to="/CentreTabledetail56"><img src={centre56} className="img-fluid product-image " alt="Mirror 1"/></Link>
          </div>
          <div className="col-12 col-sm-12 col-md-6">
          <Link to="/CentreTabledetail57"><img src={centre57} className="img-fluid product-image " alt="Mirror 1"/></Link>
          </div>
        </div>
    </>
  );
};

export default Centretable;
