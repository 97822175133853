import React from 'react';
import './Turyaproduct.css';
import NavBar from '../../Navbar/Navbar';
import { Link } from 'react-router-dom';
import Access1 from './product-pages/Access1.jpg';
import Access2 from './product-pages/Access2.jpg';
import Access3 from './product-pages/Access3.jpg';
import Access4 from './product-pages/Access4.jpg';
import Access5 from './product-pages/Access5.jpg';
import Access6 from './product-pages/Access6.jpg';
import Access7 from './product-pages/Access7.jpg';
import Access8 from './product-pages/Access8.jpg';
import Access9 from './product-pages/Access9.jpg';
import Access10 from './product-pages/Access10.jpg';
import Access11 from './product-pages/Access11.jpg';
import Access12 from './product-pages/Access12.jpg';
import Access13 from './product-pages/Access13.jpg';
import Access14 from './product-pages/Access14.jpg';
import Access15 from './product-pages/access15.jpg';
import Access16 from './product-pages/access16.jpg';
import Access17 from './product-pages/access17.jpg';
import Access18 from './product-pages/access18.jpg';
import Access19 from './product-pages/access19.jpg';
import Access20 from './product-pages/access20.jpg';
import Access21 from './product-pages/access21.jpg';
import Access22 from './product-pages/access22.jpg';
import Access23 from './product-pages/access23.jpg';
import Access24 from './product-pages/access24.jpg';
import Access25 from './product-pages/access25.jpg';
import Access26 from './product-pages/access26.jpg';
import Access27 from './product-pages/access27.jpg';
import Access28 from './product-pages/access28.jpg';
import Access29 from './product-pages/access29.jpg';
import Access30 from './product-pages/access30.jpg';
import Access31 from './product-pages/access31.jpg';
import Access32 from './product-pages/access32.jpg';
import Access33 from './product-pages/access33.jpg';
import Access34 from './product-pages/access34.jpg';
import Access35 from './product-pages/access35.jpg';
import Access36 from './product-pages/access36.jpg';


const Accessories = () => {
  return (
    <>
    <NavBar className="custom-navbars" />
    <div className="container-fluid turyaproduct">
      <div className="row turya-second-row justify-content-between">
        <div className="col-12 col-sm-12 col-md-3 turya-menu-col-1">
          <h5>TURYA DESIGNS</h5>
          <ul>
            <li>HIGHLIGHTS</li>
            <li><Link className='menu-links' to="/Turya">All</Link></li>
                    <li><Link className='menu-links' to="/seating">SEATING</Link></li>
                    <li><Link className='menu-links' to="/Bedroom">BEDROOM</Link></li>
                    <li><Link className='menu-links' to="/Dress">DRESS</Link></li>
                    <li><Link className='menu-links' to="/Mirrors">MIRRORS</Link></li>
                    <li><Link className='menu-links' to="/sidetable">SIDE TABLES</Link></li>
                    <li><Link className='menu-links' to="/Centretable">CENTRE TABLES</Link></li>
                    <li><Link className='menu-links' to="/Dinningtable">DINING TABLES</Link></li>
                    <li><Link className='menu-links' to="/Pa">PARTITIONS<span className='specialchar'>&</span> ART</Link></li>
                    <li><Link className='menu-links' to="/Drawer">CHEST OF DRAWERS</Link></li>
                    <li><Link className='menu-links' to="/Accessories">ACCESSORIES</Link></li>
          </ul>
        </div>
        <div className="col-12 col-sm-12 col-md-4 align-self-end turya-product-head">
           ACCESSORIES
        </div>
      </div> 
      <div className="row product-grid mt-4">
        <div className="col-12 col-sm-12 col-md-6">
          <Link to="/AccessoriesDetail1"><img src={Access1} className="img-fluid product-image " alt="Mirror 1"/></Link>
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-md-6">
          <div className="row inner-row-product">
            <div className="col-6">
            <Link to="/AccessoriesDetail2"><img src={Access2} className="img-fluid product-image " alt="Mirror 1"/></Link>

            </div>
            <div className="col-6 ">
            <Link to="/AccessoriesDetail3"><img src={Access3} className="img-fluid product-image " alt="Mirror 1"/></Link>
            </div>
          </div>
          <div className="row">
            <div className="col-">
            <Link to="/AccessoriesDetail4"><img src={Access4} className="img-fluid product-image " alt="Mirror 1"/></Link>
            </div>
          </div>
        </div>
        </div>
        <div className="row product-grid">
          <div className="col-12 col-sm-12 col-md-3">
          <Link to="/AccessoriesDetail5"><img src={Access5} className="img-fluid product-image " alt="Mirror 1"/></Link>
          </div>
          <div className="col-12 col-sm-12 col-md-6">
          <Link to="/AccessoriesDetail6"><img src={Access6} className="img-fluid product-image " alt="Mirror 1"/></Link>
          </div>
          <div className="col-12 col-sm-12 col-md-3">
          <Link to="/AccessoriesDetail7"><img src={Access7} className="img-fluid product-image " alt="Mirror 1"/></Link>
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12">
              <Link to="/AccessoriesDetail8"><img src={Access8} className="img-fluid product-image " alt="Mirror 1"/></Link>
              </div>
            </div>
          </div>
        </div>
        <div className="row product-grid">
          <div className="col-12 col-sm-12 col-md-6">
          <Link to="/AccessoriesDetail9"><img src={Access9} className="img-fluid product-image " alt="Mirror 1"/></Link>
          </div>
          <div className="col-12 col-sm-12 col-md-6">
          <Link to="/AccessoriesDetail10"><img src={Access10} className="img-fluid product-image " alt="Mirror 1"/></Link>
          </div>
        </div>
        <div className="row product-grid">
          <div className="col-12 col-sm-12 col-md-6">
          <Link to="/AccessoriesDetail11"><img src={Access11} className="img-fluid product-image " alt="Mirror 1"/></Link>
          </div>
          <div className="col-12 col-sm-12 col-md-6">
          <Link to="/AccessoriesDetail12"><img src={Access12} className="img-fluid product-image " alt="Mirror 1"/></Link>
          </div>
        </div>
        <div className="row product-grid">
          <div className="col-12 col-sm-12 col-md-6">
          <Link to="/AccessoriesDetail13"><img src={Access13} className="img-fluid product-image " alt="Mirror 1"/></Link>
          </div>
          <div className="col-12 col-sm-12 col-md-6">
          <Link to="/AccessoriesDetail14"><img src={Access14} className="img-fluid product-image " alt="Mirror 1"/></Link>
          </div>
        </div>

        <div className="row product-grid mt-4">
        <div className="col-12 col-sm-12 col-md-6">
          <Link to="/AccessoriesDetail15"><img src={Access15} className="img-fluid product-image " alt="Mirror 1"/></Link>
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-md-6">
          <div className="row inner-row-product">
            <div className="col-6">
            <Link to="/AccessoriesDetail16"><img src={Access16} className="img-fluid product-image " alt="Mirror 1"/></Link>

            </div>
            <div className="col-6 ">
            <Link to="/AccessoriesDetail17"><img src={Access17} className="img-fluid product-image " alt="Mirror 1"/></Link>
            </div>
          </div>
          <div className="row">
            <div className="col-">
            <Link to="/AccessoriesDetail18"><img src={Access18} className="img-fluid product-image " alt="Mirror 1"/></Link>
            </div>
          </div>
        </div>
        </div>
        <div className="row product-grid">
          <div className="col-12 col-sm-12 col-md-3">
          <Link to="/AccessoriesDetail19"><img src={Access19} className="img-fluid product-image " alt="Mirror 1"/></Link>
          </div>
          <div className="col-12 col-sm-12 col-md-6">
          <Link to="/AccessoriesDetai20"><img src={Access20} className="img-fluid product-image " alt="Mirror 1"/></Link>
          </div>
          <div className="col-12 col-sm-12 col-md-3">
          <Link to="/AccessoriesDetail21"><img src={Access21} className="img-fluid product-image " alt="Mirror 1"/></Link>
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12">
              <Link to="/AccessoriesDetaiL22"><img src={Access22} className="img-fluid product-image " alt="Mirror 1"/></Link>
              </div>
            </div>
          </div>
        </div>
        <div className="row product-grid">
          <div className="col-12 col-sm-12 col-md-6">
          <Link to="/AccessoriesDetail23"><img src={Access23} className="img-fluid product-image " alt="Mirror 1"/></Link>
          </div>
          <div className="col-12 col-sm-12 col-md-6">
          <Link to="/AccessoriesDetail24"><img src={Access24} className="img-fluid product-image " alt="Mirror 1"/></Link>
          </div>
        </div>
        <div className="row product-grid">
          <div className="col-12 col-sm-12 col-md-6">
          <Link to="/AccessoriesDetail25"><img src={Access25} className="img-fluid product-image " alt="Mirror 1"/></Link>
          </div>
          <div className="col-12 col-sm-12 col-md-6">
          <Link to="/AccessoriesDetail26"><img src={Access26} className="img-fluid product-image " alt="Mirror 1"/></Link>
          </div>
        </div>

        <div className="row product-grid mt-4">
        <div className="col-12 col-sm-12 col-md-6">
          <Link to="/AccessoriesDetail27"><img src={Access27} className="img-fluid product-image " alt="Mirror 1"/></Link>
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-md-6">
          <div className="row inner-row-product">
            <div className="col-6">
            <Link to="/AccessoriesDetail28"><img src={Access28} className="img-fluid product-image " alt="Mirror 1"/></Link>
            </div>
            <div className="col-6 ">
            <Link to="/AccessoriesDetail29"><img src={Access29} className="img-fluid product-image " alt="Mirror 1"/></Link>
            </div>
          </div>
          <div className="row">
            <div className="col-">
            <Link to="/AccessoriesDetail30"><img src={Access30} className="img-fluid product-image " alt="Mirror 1"/></Link>
            </div>
          </div>
        </div>
        </div>
        <div className="row product-grid">
          <div className="col-12 col-sm-12 col-md-3">
          <Link to="/AccessoriesDetail31"><img src={Access31} className="img-fluid product-image " alt="Mirror 1"/></Link>
          </div>
          <div className="col-12 col-sm-12 col-md-6">
          <Link to="/AccessoriesDetail32"><img src={Access32} className="img-fluid product-image " alt="Mirror 1"/></Link>
          </div>
          <div className="col-12 col-sm-12 col-md-3">
          <Link to="/AccessoriesDetail33"><img src={Access33} className="img-fluid product-image " alt="Mirror 1"/></Link>
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12">
              <Link to="/AccessoriesDetail34"><img src={Access34} className="img-fluid product-image " alt="Mirror 1"/></Link>
              </div>
            </div>
          </div>
        </div>
        <div className="row product-grid">
          <div className="col-12 col-sm-12 col-md-6">
          <Link to="/AccessoriesDetail35"><img src={Access35} className="img-fluid product-image " alt="Mirror 1"/></Link>
          </div>
          <div className="col-12 col-sm-12 col-md-6">
          <Link to="/AccessoriesDetail36"><img src={Access36} className="img-fluid product-image " alt="Mirror 1"/></Link>
          </div>
        </div>
        
       
    </div>
    </>
  );
};

export default Accessories;
