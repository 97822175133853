import React from 'react';
import './Turyaproduct.css';
import NavBar from '../../Navbar/Navbar';
import { Link } from 'react-router-dom';
import mirror1 from '../Turya-Images/mirror1.jpg';
import mirror2 from '../Turya-Images/mirror2.jpg';
import mirror3 from '../Turya-Images/mirror3.jpg';
import mirror4 from '../Turya-Images/mirror4.jpg';
import mirror5 from '../Turya-Images/mirror5.jpg';
import mirror6 from '../Turya-Images/mirror6.jpg';
import mirror7 from '../Turya-Images/mirror7.jpg';
import mirror8 from '../Turya-Images/mirror8.jpg';
import mirror9 from '../Turya-Images/mirror9.jpg';
import mirror10 from '../Turya-Images/mirror10.jpg';
import mirror11 from '../Turya-Images/mirror11.jpg';
import mirror12 from '../Turya-Images/mirror12.jpg';
import mirror14 from '../Turya-Images/mirror13.jpg';
import mirror15 from '../Turya-Images/mirror15.jpg';
import mirror16 from '../Turya-Images/mirror16.jpg';
import mirror17 from '../Turya-Images/mirror17.jpg';
import mirror18 from '../Turya-Images/mirror18.jpg';
import mirror19 from '../Turya-Images/mirror19.jpg';
import mirror20 from '../Turya-Images/mirror20.jpg';
import mirror21 from '../Turya-Images/mirror21.jpg';
import mirror22 from '../Turya-Images/mirror22.jpg';

const Mirrors = () => {
  return (
    <>
    <NavBar className="custom-navbars" />
    <div className="container-fluid turyaproduct">
      <div className="row turya-second-row justify-content-between">
        <div className="col-12 col-sm-12 col-md-3 turya-menu-col-1">
          <h5>TURYA DESIGNS</h5>
          <ul>
            <li>HIGHLIGHTS</li>
            <li><Link className='menu-links' to="/Turya">All</Link></li>
                    <li><Link className='menu-links' to="/seating">SEATING</Link></li>
                    <li><Link className='menu-links' to="/Bedroom">BEDROOM</Link></li>
                    <li><Link className='menu-links' to="/Dress">DRESS</Link></li>
                    <li><Link className='menu-links' to="/Mirrors">MIRRORS</Link></li>
                    <li><Link className='menu-links' to="/sidetable">SIDE TABLES</Link></li>
                    <li><Link className='menu-links' to="/Centretable">CENTRE TABLES</Link></li>
                    <li><Link className='menu-links' to="/Dinningtable">DINING TABLES</Link></li>
                    <li><Link className='menu-links' to="/Pa">PARTITIONS<span className='specialchar'>&</span> ART</Link></li>
                    <li><Link className='menu-links' to="/Drawer">CHEST OF DRAWERS</Link></li>
                    <li><Link className='menu-links' to="/Accessories">ACCESSORIES</Link></li>
          </ul>
        </div>
        <div className="col-12 col-sm-12 col-md-4 align-self-end turya-product-head">
           MIRROR
        </div>
      </div> 
      <div className="row product-grid mt-4">
        <div className="col-12 col-sm-12 col-md-6">
          <Link to="/MirrorDetail1"><img src={mirror1} className="img-fluid product-image " alt="Mirror 1"/></Link>
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-md-6">
          <div className="row inner-row-product">
            <div className="col-6">
           
            <Link to="/MirrorDetail2"><img src={mirror8} className="img-fluid product-image " alt="Mirror 1"/></Link>
            </div>
            <div className="col-6 ">
            <Link to="/MirrorDetail3"><img src={mirror7} className="img-fluid product-image " alt="Mirror 1"/></Link>
            </div>
          </div>
          <div className="row">
            <div className="col-">
            <Link to="/MirrorDetail4"><img src={mirror3} className="img-fluid product-image " alt="Mirror 1"/></Link>
            </div>
          </div>
        </div>
        </div>
        <div className="row product-grid">
          <div className="col-12 col-sm-12 col-md-3">
          <Link to="/MirrorDetail5"><img src={mirror4} className="img-fluid product-image " alt="Mirror 1"/></Link>
          </div>
          <div className="col-12 col-sm-12 col-md-6">
          <Link to="/MirrorDetail6"><img src={mirror5} className="img-fluid product-image " alt="Mirror 1"/></Link>
          </div>
          <div className="col-12 col-sm-12 col-md-3">
          <Link to="/MirrorDetail7"><img src={mirror2} className="img-fluid product-image " alt="Mirror 1"/></Link>
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12">
              <Link to="/MirrorDetail8"><img src={mirror10} className="img-fluid product-image " alt="Mirror 1"/></Link>
              </div>
            </div>
          </div>
        </div>
        <div className="row product-grid">
          <div className="col-12 col-sm-12 col-md-6">
          <Link to="/MirrorDetail9"><img src={mirror6} className="img-fluid product-image " alt="Mirror 1"/></Link>
          </div>
          <div className="col-12 col-sm-12 col-md-6">
          <Link to="/MirrorDetail10"><img src={mirror9} className="img-fluid product-image " alt="Mirror 1"/></Link>
          </div>
        </div>
        <div className="row product-grid">
          <div className="col-12 col-sm-12 col-md-6">
          <Link to="/MirrorDetail11"><img src={mirror11} className="img-fluid product-image " alt="Mirror 1"/></Link>
          </div>
          <div className="col-12 col-sm-12 col-md-6">
          <Link to="/MirrorDetail12"><img src={mirror12} className="img-fluid product-image " alt="Mirror 1"/></Link>
          </div>
        </div>
        <div className="row product-grid mt-4">
        <div className="col-12 col-sm-12 col-md-6">
          <Link to="/MirrorDetail13"><img src={mirror14} className="img-fluid product-image " alt="Mirror 1"/></Link>
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-md-6">
          <div className="row inner-row-product">
            <div className="col-6">
           
            <Link to="/MirrorDetail14"><img src={mirror15} className="img-fluid product-image " alt="Mirror 1"/></Link>
            </div>
            <div className="col-6 ">
            <Link to="/MirrorDetail15"><img src={mirror16} className="img-fluid product-image " alt="Mirror 1"/></Link>
            </div>
          </div>
          <div className="row">
            <div className="col-">
            <Link to="/MirrorDetail16"><img src={mirror17} className="img-fluid product-image " alt="Mirror 1"/></Link>
            </div>
          </div>
        </div>
        </div>
        <div className="row product-grid">
          <div className="col-12 col-sm-12 col-md-3">
          <Link to="/MirrorDetail17"><img src={mirror18} className="img-fluid product-image " alt="Mirror 1"/></Link>
          </div>
          <div className="col-12 col-sm-12 col-md-6">
          <Link to="/MirrorDetail18"><img src={mirror19} className="img-fluid product-image " alt="Mirror 1"/></Link>
          </div>
          <div className="col-12 col-sm-12 col-md-3">
          <Link to="/MirrorDetail19"><img src={mirror20} className="img-fluid product-image " alt="Mirror 1"/></Link>
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12">
              <Link to="/MirrorDetail20"><img src={mirror21} className="img-fluid product-image " alt="Mirror 1"/></Link>
              </div>
            </div>
          </div>
        </div>
        <div className="row product-grid">
          <div className="col-12 col-sm-12 col-md-6">
          <Link to="/MirrorDetail21"><img src={mirror22} className="img-fluid product-image " alt="Mirror 1"/></Link>
          </div>
          
        </div>
    </div>
    </>
  );
};

export default Mirrors;
