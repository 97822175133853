import React from 'react';
import './Turyaproduct.css';
import NavBar from '../../Navbar/Navbar';
import { Link } from 'react-router-dom';
import seating1 from './product-pages/Seating1.jpg';
import seating2 from './product-pages/Seating2.jpg';
import seating3 from './product-pages/seating3.jpg';
import seating4 from './product-pages/seating4.jpg';
import seating5 from './product-pages/seating5.jpg';
import seating6 from './product-pages/seating6.jpg';
import seating7 from './product-pages/seating7.jpg';
import seating8 from './product-pages/seating8.jpg';
import seating9 from './product-pages/seating9.jpg';
import seating10 from './product-pages/seating10.jpg';
import seating11 from './product-pages/seating11.jpg';
import seating12 from './product-pages/seating12.jpg';
import seating13 from './product-pages/seating13.jpg';
import seating14 from './product-pages/seating14.jpg';
import seating15 from './product-pages/seating15.jpg';
import seating16 from './product-pages/seating16.jpg';
import seating17 from './product-pages/seating17.jpg';
import seating18 from './product-pages/seating18.jpg';
import seating19 from './product-pages/seating19.jpg';
import seating20 from './product-pages/seating20.jpg';
import seating21 from './product-pages/seating21.png';
import seating22 from './product-pages/seating22.jpg';
import seating23 from './product-pages/seating23.jpg';
import seating24 from './product-pages/seating24.jpg';
import seating25 from './product-pages/seating25.jpg';
import seating26 from './product-pages/seating26.jpg';
import seating27 from './product-pages/seating27.jpg';
import seating28 from './product-pages/seating28.jpg';
import seating29 from './product-pages/seating29.jpg';
import seating30 from './product-pages/seating30.jpg';
import seating31 from './product-pages/seating31.jpg';
import seating32 from './product-pages/seating32.jpg';
import seating33 from './product-pages/seating33.jpg';
import seating34 from './product-pages/seating34.jpg';
import seating35 from './product-pages/seating35.jpg';
import seating36 from './product-pages/seating36.jpg';
import seating37 from './product-pages/seating37.jpg'
import seating38 from './product-pages/seating38.jpg'

const Seating = () => {
  return (
    <>
     <NavBar className="custom-navbars" />
    <div className="container-fluid turyaproduct">
      <div className="row turya-second-row justify-content-between">
        <div className="col-12 col-sm-12 col-md-3 turya-menu-col-1">
          <h5>TURYA DESIGNS</h5>
          <ul>
            <li>HIGHLIGHTS</li>
            <li><Link className='menu-links' to="/Turya">All</Link></li>
                    <li><Link className='menu-links' to="/seating">SEATING</Link></li>
                    <li><Link className='menu-links' to="/Bedroom">BEDROOM</Link></li>
                    <li><Link className='menu-links' to="/Dress">DRESS</Link></li>
                    <li><Link className='menu-links' to="/Mirrors">MIRRORS</Link></li>
                    <li><Link className='menu-links' to="/sidetable">SIDE TABLES</Link></li>
                    <li><Link className='menu-links' to="/Centretable">CENTRE TABLES</Link></li>
                    <li><Link className='menu-links' to="/Dinningtable">DINING TABLES</Link></li>
                    <li><Link className='menu-links' to="/Pa">PARTITIONS<span className='specialchar'>&</span> ART</Link></li>
                    <li><Link className='menu-links' to="/Drawer">CHEST OF DRAWERS</Link></li>
                    <li><Link className='menu-links' to="/Accessories">ACCESSORIES</Link></li>
          </ul>
        </div>
        <div className="col-12 col-sm-12 col-md-4 align-self-end turya-product-head">
           SEATING
        </div>
      </div> 
      <div className="row product-grid mt-4">
        <div className="col-12 col-sm-12 col-md-6">
          <Link to="/SeatingDetail1"><img src={seating4} className="img-fluid product-image " alt="Mirror 1"/></Link>
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-md-6">
          <div className="row inner-row-product">
            <div className="col-6">
           
            <Link to="/SeatingDetail2"><img src={seating2} className="img-fluid product-image " alt="Mirror 1"/></Link>
            </div>
            <div className="col-6 ">
            <Link to="/SeatingDetail3"><img src={seating3} className="img-fluid product-image " alt="Mirror 1"/></Link>
            </div>
          </div>
          <div className="row">
            <div className="col-">
            <Link to="/SeatingDetail4"><img src={seating1} className="img-fluid product-image " alt="Mirror 1"/></Link>
            </div>
          </div>
        </div>
        </div>
        <div className="row product-grid">
          <div className="col-12 col-sm-12 col-md-3">
          <Link to="/SeatingDetail5"><img src={seating5} className="img-fluid product-image " alt="Mirror 1"/></Link>
          </div>
          <div className="col-12 col-sm-12 col-md-6">
          <Link to="/SeatingDetail6"><img src={seating6} className="img-fluid product-image " alt="Mirror 1"/></Link>
          </div>
          <div className="col-12 col-sm-12 col-md-3">
          <Link to="/SeatingDetail7"><img src={seating7} className="img-fluid product-image " alt="Mirror 1"/></Link>
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12">
              <Link to="/SeatingDetail8"><img src={seating8} className="img-fluid product-image " alt="Mirror 1"/></Link>
              </div>
            </div>
          </div>
        </div>
        <div className="row product-grid">
          <div className="col-12 col-sm-12 col-md-6">
          <Link to="/SeatingDetail9"><img src={seating9} className="img-fluid product-image " alt="Mirror 1"/></Link>
          </div>
          <div className="col-12 col-sm-12 col-md-6">
          <Link to="/SeatingDetail10"><img src={seating10} className="img-fluid product-image " alt="Mirror 1"/></Link>
          </div>
        </div>
        <div className="row product-grid">
          <div className="col-12 col-sm-12 col-md-6">
          <Link to="/SeatingDetail11"><img src={seating11} className="img-fluid product-image " alt="Mirror 1"/></Link>
          </div>
          <div className="col-12 col-sm-12 col-md-6">
          <Link to="/SeatingDetail12"><img src={seating12} className="img-fluid product-image " alt="Mirror 1"/></Link>
          </div>
        </div>
        <div className="row product-grid mt-4">
        <div className="col-12 col-sm-12 col-md-6">
          <Link to="/SeatingDetail13"><img src={seating13} className="img-fluid product-image " alt="Mirror 1"/></Link>
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-md-6">
          <div className="row inner-row-product">
            <div className="col-6">
           
            <Link to="/SeatingDetail14"><img src={seating14} className="img-fluid product-image " alt="Mirror 1"/></Link>
            </div>
            <div className="col-6 ">
            <Link to="/SeatingDetail15"><img src={seating15} className="img-fluid product-image " alt="Mirror 1"/></Link>
            </div>
          </div>
          <div className="row">
            <div className="col-">
            <Link to="/SeatingDetail16"><img src={seating16} className="img-fluid product-image " alt="Mirror 1"/></Link>
            </div>
          </div>
        </div>
        </div>
        <div className="row product-grid">
          <div className="col-12 col-sm-12 col-md-3">
          <Link to="/SeatingDetail17"><img src={seating17} className="img-fluid product-image " alt="Mirror 1"/></Link>
          </div>
          <div className="col-12 col-sm-12 col-md-6">
          <Link to="/SeatingDetail18"><img src={seating18} className="img-fluid product-image " alt="Mirror 1"/></Link>
          </div>
          <div className="col-12 col-sm-12 col-md-3">
          <Link to="/SeatingDetail19"><img src={seating19} className="img-fluid product-image " alt="Mirror 1"/></Link>
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12">
              <Link to="/SeatingDetail20"><img src={seating20} className="img-fluid product-image " alt="Mirror 1"/></Link>
              </div>
            </div>
          </div>
        </div>
        </div>
        <div className="row product-grid">
          <div className="col-12 col-sm-12 col-md-3">
          <Link to="/SeatingDetail21"><img src={seating21} className="img-fluid product-image " alt="Mirror 1"/></Link>
          </div>
          <div className="col-12 col-sm-12 col-md-6">
          <Link to="/SeatingDetail22"><img src={seating22} className="img-fluid product-image " alt="Mirror 1"/></Link>
          </div>
          <div className="col-12 col-sm-12 col-md-3">
          <Link to="/SeatingDetail23"><img src={seating23} className="img-fluid product-image " alt="Mirror 1"/></Link>
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12">
              <Link to="/SeatingDetail24"><img src={seating24} className="img-fluid product-image " alt="Mirror 1"/></Link>
              </div>
            </div>
          </div>
        </div>
        <div className="row product-grid">
          <div className="col-12 col-sm-12 col-md-3">
          <Link to="/SeatingDetail25"><img src={seating25} className="img-fluid product-image " alt="Mirror 1"/></Link>
          </div>
          <div className="col-12 col-sm-12 col-md-6">
          <Link to="/SeatingDetail26"><img src={seating26} className="img-fluid product-image " alt="Mirror 1"/></Link>
          </div>
          <div className="col-12 col-sm-12 col-md-3">
          <Link to="/SeatingDetail27"><img src={seating27} className="img-fluid product-image " alt="Mirror 1"/></Link>
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12">
              <Link to="/SeatingDetail28"><img src={seating28} className="img-fluid product-image " alt="Mirror 1"/></Link>
              </div>
            </div>
          </div>
        </div>
        <div className="row product-grid">
          <div className="col-12 col-sm-12 col-md-6">
          <Link to="/SeatingDetail29"><img src={seating29} className="img-fluid product-image " alt="Mirror 1"/></Link>
          </div>
          <div className="col-12 col-sm-12 col-md-6">
          <Link to="/SeatingDetail30"><img src={seating30} className="img-fluid product-image " alt="Mirror 1"/></Link>
          </div>
        </div>
        <div className="row product-grid">
          <div className="col-12 col-sm-12 col-md-6">
          <Link to="/SeatingDetail31"><img src={seating31} className="img-fluid product-image " alt="Mirror 1"/></Link>
          </div>
          <div className="col-12 col-sm-12 col-md-6">
          <Link to="/SeatingDetail32"><img src={seating32} className="img-fluid product-image " alt="Mirror 1"/></Link>
          </div>
        </div>
        <div className="row product-grid">
          <div className="col-12 col-sm-12 col-md-3">
          <Link to="/SeatingDetail33"><img src={seating33} className="img-fluid product-image " alt="Mirror 1"/></Link>
          </div>
          <div className="col-12 col-sm-12 col-md-6">
          <Link to="/SeatingDetail34"><img src={seating34} className="img-fluid product-image " alt="Mirror 1"/></Link>
          </div>
          <div className="col-12 col-sm-12 col-md-3">
          <Link to="/SeatingDetail35"><img src={seating35} className="img-fluid product-image " alt="Mirror 1"/></Link>
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12">
              <Link to="/SeatingDetail36"><img src={seating36} className="img-fluid product-image " alt="Mirror 1"/></Link>
              </div>
            </div>
          </div>
        </div>
        <div className="row product-grid">
          <div className="col-12 col-sm-12 col-md-6">
          <Link to="/SeatingDetail37"><img src={seating37} className="img-fluid product-image " alt="Mirror 1"/></Link>
          </div>
          <div className="col-12 col-sm-12 col-md-6">
          <Link to="/SeatingDetail38"><img src={seating38} className="img-fluid product-image " alt="Mirror 1"/></Link>
          </div>
        </div>
    </>
  );
};

export default Seating;
