import React from 'react';
import './Turyaproduct.css';
import NavBar from '../../Navbar/Navbar';
import { Link } from 'react-router-dom';
import dinning1 from './product-pages/dinning1.jpg';
import dinning2 from './product-pages/dinning2.jpg';
import dinning3 from './product-pages/dinning3.jpg';
import dinning4 from './product-pages/dinning4.jpg';


const Dinningtable = () => {
  return (
    <>
    <NavBar className="custom-navbars" />
    <div className="container-fluid turyaproduct">
      <div className="row turya-second-row justify-content-between">
        <div className="col-12 col-sm-12 col-md-3 turya-menu-col-1">
          <h5>TURYA DESIGNS</h5>
          <ul>
          <li>HIGHLIGHTS</li>
          <li><Link className='menu-links' to="/Turya">All</Link></li>
                    <li><Link className='menu-links' to="/seating">SEATING</Link></li>
                    <li><Link className='menu-links' to="/Bedroom">BEDROOM</Link></li>
                    <li><Link className='menu-links' to="/Dress">DRESS</Link></li>
                    <li><Link className='menu-links' to="/Mirrors">MIRRORS</Link></li>
                    <li><Link className='menu-links' to="/sidetable">SIDE TABLES</Link></li>
                    <li><Link className='menu-links' to="/Centretable">CENTRE TABLES</Link></li>
                    <li><Link className='menu-links' to="/Dinningtable">DINING TABLES</Link></li>
                    <li><Link className='menu-links' to="/Pa">PARTITIONS<span className='specialchar'>&</span> ART</Link></li>
                    <li><Link className='menu-links' to="/Drawer">CHEST OF DRAWERS</Link></li>
                    <li><Link className='menu-links' to="/Accessories">ACCESSORIES</Link></li>
          </ul>
        </div>
        <div className="col-12 col-sm-12 col-md-4 align-self-end turya-product-head">
           DINNING TABLE
        </div>
      </div> 
      <div className="row product-grid mt-4">
        <div className="col-12 col-sm-12 col-md-6">
          <Link to="/DinningtableDetail1"><img src={dinning1} className="img-fluid product-image " alt="Mirror 1"/></Link>
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-md-6">
          <div className="row inner-row-product">
            <div className="col-6">
            <Link to="/DinningtableDetail2"><img src={dinning2} className="img-fluid product-image " alt="Mirror 1"/></Link>

            </div>
            <div className="col-6 ">
            <Link to="/DinningtableDetail3"><img src={dinning3} className="img-fluid product-image " alt="Mirror 1"/></Link>
            </div>
          </div>
          <div className="row">
            <div className="col-">
            <Link to="/DinningtableDetail4"><img src={dinning4} className="img-fluid product-image " alt="Mirror 1"/></Link>
            </div>
          </div>
        </div>
        </div>
       
       
     
    </div>
    </>
  );
};

export default Dinningtable;
