import React, { useEffect } from 'react';
import './Turya.css';
import NavBar from '../Navbar/Navbar';
import { Link } from 'react-router-dom';
import logo from '../images/logo.png';
import product1 from './Turya-Images/product1.webp';
import product2 from './Turya-Images/product2.webp';
import product3 from './Turya-Images/product3.webp';
import product4 from './Turya-Images/product4.webp';
import product5 from './Turya-Images/product5.webp';
import product6 from './Turya-Images/product6.webp';
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Turya = () => {
  const { t, i18n } = useTranslation('global');

  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <div>
      <NavBar className="custom-navbars" />
      <div className="container-fluid turya-landing-4">
        <div className='row turya-land-head-up'>
          <div className="col-9 col-sm-5 col-md-10 turya-land-col1">{t('turya-land-head')}</div>
          <div className="col-3 col-sm-3 col-md-2 turya-land-col2"><LazyLoadImage src={logo} alt="" loading='lazy' /></div>
        </div>
        <div className='turya-land-down'>{t('turya-designs')}</div>
        
        {/* Language Toggle Buttons Inside First Container */}
        <div className="language-buttons">
          <button onClick={() => handleLanguageChange('en')} className="btn btn-outline-primary">English</button>
          <button onClick={() => handleLanguageChange('ta')} className="btn btn-outline-primary">தமிழ்</button>
        </div>
      </div>

      <div className="container-fluid turya-second">
        <div className="row turya-second-row">
          <div className="col-10 col-sm-10 col-md-3 col-lg-2 turya-menu-col">
            <ul>
              <li>{t('turya-highlights')}</li>
              <li><Link className='menu-links' to="/Turya">{t('turya-all')}</Link></li>
              <li><Link className='menu-links' to="/seating">{t('turya-seating')}</Link></li>
              <li><Link className='menu-links' to="/Bedroom">{t('turya-bedroom')}</Link></li>
              <li><Link className='menu-links' to="/Dress">{t('turya-dress')}</Link></li>
              <li><Link className='menu-links' to="/Mirrors">{t('turya-mirrors')}</Link></li>
              <li><Link className='menu-links' to="/sidetable">{t('turya-sidetable')}</Link></li>
              <li><Link className='menu-links' to="/Centretable">{t('turya-centretable')}</Link></li>
              <li><Link className='menu-links' to="/Dinningtable">{t('turya-diningtable')}</Link></li>
              <li><Link className='menu-links' to="/Pa">{t('turya-partitions')}<span className='spclchar'>&</span> {t('turya-art')}</Link></li>
              <li><Link className='menu-links' to="/Drawer">{t('turya-drawers')}</Link></li>
              <li><Link className='menu-links' to="/Accessories">{t('turya-accessories')}</Link></li>
            </ul>
          </div>
          <div className="col-10 col-sm-10 col-md-9 col-lg-10 turya-heading">
            {t('turya-main-head')}
          </div>
        </div>
        
        {/* Products Section */}
        <div className="row turya-product-row">
          <div className="col product-col">
            <div className='product-title'><h6>001</h6><h5>{t('turya-bamboo')}</h5></div>
            <LazyLoadImage className='product-img' src={product1} alt="" loading='lazy' />
          </div>
          <div className="col product-col">
            <div className='product-title'><h6>002</h6><h5>{t('turya-elemental')}</h5></div>
            <LazyLoadImage className='product-img' src={product2} alt="" loading='lazy' />
          </div>
          <div className="col product-col">
            <div className='product-title'><h6>003</h6><h5>{t('turya-allignment')}</h5></div>
            <LazyLoadImage className='product-img' src={product3} alt="" loading='lazy' />
          </div>
        </div>

        <div className="row turya-product-row turya-product-row-2 align-self-end">
          <div className="col-7 product-col row-2-col"></div>
          <div className="col-12 col-sm-12 col-md-5 product-col row-2-col">
            <div className='product-title'><h6>004</h6><h5>{t('turya-thingofbeauty')}</h5></div>
            <LazyLoadImage className='product-img-2-row' src={product4} alt="" loading='lazy' />
          </div>
        </div>

        <div className="row turya-product-row turya-product-row-2 align-self-end">
          <div className="col-6 col-sm-6 col-md-4 product-col row-2-col">
            <div className='product-title'><h6>005</h6><h5>{t('turya-chestofdrawers')}</h5></div>
            <LazyLoadImage className='product-img-2-row' src={product5} alt="" loading='lazy' />
          </div>
          <div className="col-6 col-sm-6 col-md-4 product-col row-2-col">
            <div className='product-title'><h6>006</h6><h5>{t('turya-table')}</h5></div>
            <LazyLoadImage className='product-img-2-row' src={product6} alt="" loading='lazy' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Turya;
