import React from 'react'
import './Detailproduct.css'
import NavBar from '../../../Navbar/Navbar';
import bamoon1 from '../product-pages/BA-Moon-M (1).jpg';
import bamoon2 from '../product-pages/BA-Moon-M (3).jpg';
import bamoon3 from '../product-pages/Access1.jpg';
import { useTranslation } from 'react-i18next';

const AccessoriesDetail1 = () => {
    const { t } = useTranslation('global');
  return (
    <div>
                <NavBar className="turya-custom-navbar" />
        <div className="container-fluid detail-product">
            <div className="contain">
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-4">
                        <img className='image-fluid product-image' src={bamoon1} alt="" srcset="" />
                    </div>
                    <div className="col-12 col-sm-12 col-md-4">
                        <img className='image-fluid product-image' src={bamoon2} alt="" srcset="" />
                    </div>
                    <div className="col-12 col-sm-12 col-md-4">
                        <img className='image-fluid product-image' src={bamoon3} alt="" srcset="" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-6">
                        <img className='image-fluid product-image' src={bamoon1} alt="" srcset="" />
                    </div>
                    <div className="col-12 col-sm-12 col-md-6">
                        <img className='image-fluid product-image' src={bamoon2} alt="" srcset="" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-10 col-sm-10 col-md-6">
                        <p className='product-detail-head'>{t('turya-designs')}</p>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6">
                        <h5 className='para-head'>{t('turya-elemental')}</h5>
                     <p className='product-detail-para'>{t('turya-detail-para')}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default AccessoriesDetail1