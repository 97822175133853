import React from 'react';
import './Turyaproduct.css';
import NavBar from '../../Navbar/Navbar';
import { Link } from 'react-router-dom';
import pa1 from './product-pages/PA1.jpg';
import pa2 from './product-pages/PA2.jpg';
import pa3 from './product-pages/PA3.jpg';
import pa4 from './product-pages/PA4.jpg';
import pa5 from './product-pages/PA5.jpg';
import pa6 from './product-pages/PA6.jpg';
import pa7 from './product-pages/PA7.jpg';
import pa8 from './product-pages/PA8.jpg';
import pa9 from './product-pages/PA9.jpg';
import pa10 from './product-pages/PA10.jpg';
import pa11 from './product-pages/PA11.jpg';



const Pa = () => {
  return (
    <>
    <NavBar className="custom-navbars" />
    <div className="container-fluid turyaproduct">
      <div className="row turya-second-row justify-content-between">
        <div className="col-12 col-sm-12 col-md-3 turya-menu-col-1">
          <h5>TURYA DESIGNS</h5>
          <ul>
            <li>HIGHLIGHTS</li>
            <li><Link className='menu-links' to="/Turya">All</Link></li>
                    <li><Link className='menu-links' to="/seating">SEATING</Link></li>
                    <li><Link className='menu-links' to="/Bedroom">BEDROOM</Link></li>
                    <li><Link className='menu-links' to="/Dress">DRESS</Link></li>
                    <li><Link className='menu-links' to="/Mirrors">MIRRORS</Link></li>
                    <li><Link className='menu-links' to="/sidetable">SIDE TABLES</Link></li>
                    <li><Link className='menu-links' to="/Centretable">CENTRE TABLES</Link></li>
                    <li><Link className='menu-links' to="/Dinningtable">DINING TABLES</Link></li>
                    <li><Link className='menu-links' to="/Pa">PARTITIONS<span className='specialchar'>&</span> ART</Link></li>
                    <li><Link className='menu-links' to="/Drawer">CHEST OF DRAWERS</Link></li>
                    <li><Link className='menu-links' to="/Accessories">ACCESSORIES</Link></li>
          </ul>
        </div>
        <div className="col-12 col-sm-12 col-md-4 align-self-end turya-product-head">
        PARTITIONS & ART
        </div>
      </div> 
      <div className="row product-grid mt-4">
        <div className="col-12 col-sm-12 col-md-6">
          <Link to="/PaDetail1"><img src={pa4} className="img-fluid product-image " alt="Mirror 1"/></Link>
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-md-6">
          <div className="row inner-row-product">
            <div className="col-6">
            <Link to="/PaDetail2"><img src={pa2} className="img-fluid product-image " alt="Mirror 1"/></Link>

            </div>
            <div className="col-6 ">
            <Link to="/PaDetail3"><img src={pa5} className="img-fluid product-image " alt="Mirror 1"/></Link>
            </div>
          </div>
          <div className="row">
            <div className="col-">
            <Link to="/PaDetail4"><img src={pa1} className="img-fluid product-image " alt="Mirror 1"/></Link>
            </div>
          </div>
        </div>
        </div>
        <div className="row product-grid">
          <div className="col-12 col-sm-12 col-md-3">
          <Link to="/PaDetail5"><img src={pa3} className="img-fluid product-image " alt="Mirror 1"/></Link>
          </div>
          <div className="col-12 col-sm-12 col-md-6">
          <Link to="/PaDetail6"><img src={pa6} className="img-fluid product-image " alt="Mirror 1"/></Link>
          </div>
          <div className="col-12 col-sm-12 col-md-3">
          <Link to="/PaDetail7"><img src={pa7} className="img-fluid product-image " alt="Mirror 1"/></Link>
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12">
              <Link to="/PaDetail8"><img src={pa8} className="img-fluid product-image " alt="Mirror 1"/></Link>
              </div>
            </div>
          </div>
        </div>
        <div className="row product-grid">
          <div className="col-12 col-sm-12 col-md-6">
          <Link to="/PaDetail9"><img src={pa9} className="img-fluid product-image " alt="Mirror 1"/></Link>
          </div>
          <div className="col-12 col-sm-12 col-md-6">
          <Link to="/PaDetail10"><img src={pa10} className="img-fluid product-image " alt="Mirror 1"/></Link>
          </div>
        </div>
        <div className="row product-grid">
          <div className="col-12 col-sm-12 col-md-6">
          <Link to="/PaDetail11"><img src={pa11} className="img-fluid product-image " alt="Mirror 1"/></Link>
          </div>
          
        </div>
    </div>
    </>
  );
};

export default Pa;
