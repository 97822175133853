import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Turyaproduct from './Components/Turya/Turyaproduct';
import Detailproduct from './Components/Turya/Detailproduct';
import Turya from './Components/Turya/Turya';
import Seating from './Components/Turya/Turya-categorypages/Seating';
import Bedroom from './Components/Turya/Turya-categorypages/Bedroom';
import Mirrors from './Components/Turya/Turya-categorypages/Mirrors';
import Dress from './Components/Turya/Turya-categorypages/Dress';
import Sidetable from './Components/Turya/Turya-categorypages/Side-Table';
import Centretable from './Components/Turya/Turya-categorypages/Centretable';
import Dinningtable from './Components/Turya/Turya-categorypages/Dinningtable';
import Pa from './Components/Turya/Turya-categorypages/Pa';
import Drawer from './Components/Turya/Turya-categorypages/Drawer';
import Accessories from './Components/Turya/Turya-categorypages/Accessories';
import SeatingDetail1 from './Components/Turya/Turya-categorypages/Detailed-turya/SeatingDetail1';
import MirrorDetail1 from './Components/Turya/Turya-categorypages/Detailed-turya/MirrorDetail1';
import MirrorDetail2 from './Components/Turya/Turya-categorypages/Detailed-turya/MirrorDetail2';
import MirrorDetail3 from './Components/Turya/Turya-categorypages/Detailed-turya/MirrorDetail3';
import MirrorDetail4 from './Components/Turya/Turya-categorypages/Detailed-turya/MirrorDetail4';
import MirrorDetail5 from './Components/Turya/Turya-categorypages/Detailed-turya/MirrorDetail5';
import MirrorDetail6 from './Components/Turya/Turya-categorypages/Detailed-turya/MirrorDetail6';
import MirrorDetail7 from './Components/Turya/Turya-categorypages/Detailed-turya/MirrorDetail7';
import MirrorDetail8 from './Components/Turya/Turya-categorypages/Detailed-turya/MirrorDetail8';
import MirrorDetail9 from './Components/Turya/Turya-categorypages/Detailed-turya/MirrorDetail9';
import MirrorDetail10 from './Components/Turya/Turya-categorypages/Detailed-turya/MirrorDetail10';
import MirrorDetail11 from './Components/Turya/Turya-categorypages/Detailed-turya/MirrorDetail11';
import MirrorDetail12 from './Components/Turya/Turya-categorypages/Detailed-turya/MirrorDetail12';
import SidetableDetail1 from './Components/Turya/Turya-categorypages/Detailed-turya/SidetableDetail1';
import SidetableDetail2 from './Components/Turya/Turya-categorypages/Detailed-turya/SidetableDetail2';
import SidetableDetail3 from './Components/Turya/Turya-categorypages/Detailed-turya/SidetableDetail3';
import DinningtableDetail1 from './Components/Turya/Turya-categorypages/Detailed-turya/DinningtableDetail1';
import DinningtableDetail2 from './Components/Turya/Turya-categorypages/Detailed-turya/DinningtableDetail2';
import DinningtableDetail3 from './Components/Turya/Turya-categorypages/Detailed-turya/DinningtableDetail3';
import DinningtableDetail4 from './Components/Turya/Turya-categorypages/Detailed-turya/DinningtableDetail4';
import PaDetail1 from './Components/Turya/Turya-categorypages/Detailed-turya/PaDetail1';
import PaDetail2 from './Components/Turya/Turya-categorypages/Detailed-turya/PaDetail2';
import PaDetail3 from './Components/Turya/Turya-categorypages/Detailed-turya/PaDetail3';
import PaDetail4 from './Components/Turya/Turya-categorypages/Detailed-turya/PaDetail4';
import PaDetail5 from './Components/Turya/Turya-categorypages/Detailed-turya/PaDetail5';
import PaDetail6 from './Components/Turya/Turya-categorypages/Detailed-turya/PaDetail6';
import PaDetail7 from './Components/Turya/Turya-categorypages/Detailed-turya/PaDetail7';
import PaDetail8 from './Components/Turya/Turya-categorypages/Detailed-turya/PaDetail8';
import PaDetail9 from './Components/Turya/Turya-categorypages/Detailed-turya/PaDetail9';
import PaDetail10 from './Components/Turya/Turya-categorypages/Detailed-turya/PaDetail10';
import PaDetail11 from './Components/Turya/Turya-categorypages/Detailed-turya/PaDetail11';
import AccessoriesDetail1 from './Components/Turya/Turya-categorypages/Detailed-turya/AccessoriesDetail1';
import AccessoriesDetail2 from './Components/Turya/Turya-categorypages/Detailed-turya/AccessoriesDetail2';
import AccessoriesDetail3 from './Components/Turya/Turya-categorypages/Detailed-turya/AccessoriesDetail3';
import AccessoriesDetail4 from './Components/Turya/Turya-categorypages/Detailed-turya/AccessoriesDetail4';
import AccessoriesDetail5 from './Components/Turya/Turya-categorypages/Detailed-turya/AccessoriesDetail5';
import AccessoriesDetail6 from './Components/Turya/Turya-categorypages/Detailed-turya/AccessoriesDetail6';
import AccessoriesDetail7 from './Components/Turya/Turya-categorypages/Detailed-turya/AccessoriesDetail7';
import AccessoriesDetail8 from './Components/Turya/Turya-categorypages/Detailed-turya/AccessoriesDetail8';
import AccessoriesDetail9 from './Components/Turya/Turya-categorypages/Detailed-turya/AccessoriesDetail9';
import AccessoriesDetail10 from './Components/Turya/Turya-categorypages/Detailed-turya/AccessoriesDetail10';
import AccessoriesDetail11 from './Components/Turya/Turya-categorypages/Detailed-turya/AccessoriesDetail11';
import AccessoriesDetail12 from './Components/Turya/Turya-categorypages/Detailed-turya/AccessoriesDetail12';
import AccessoriesDetail13 from './Components/Turya/Turya-categorypages/Detailed-turya/AccessoriesDetail13';
import AccessoriesDetail14 from './Components/Turya/Turya-categorypages/Detailed-turya/AccessoriesDetail14';
import AccessoriesDetail15 from './Components/Turya/Turya-categorypages/Detailed-turya/AccessoriesDetail15';
import AccessoriesDetail16 from './Components/Turya/Turya-categorypages/Detailed-turya/AccessoriesDetail16';
import AccessoriesDetail17 from './Components/Turya/Turya-categorypages/Detailed-turya/AccessoriesDetail17';
import AccessoriesDetail18 from './Components/Turya/Turya-categorypages/Detailed-turya/AccessoriesDetail18';
import AccessoriesDetail19 from './Components/Turya/Turya-categorypages/Detailed-turya/AccessoriesDetail19';
import AccessoriesDetail20 from './Components/Turya/Turya-categorypages/Detailed-turya/AccessoriesDetail20';
import AccessoriesDetail21 from './Components/Turya/Turya-categorypages/Detailed-turya/AccessoriesDetail21';
import AccessoriesDetail22 from './Components/Turya/Turya-categorypages/Detailed-turya/AccessoriesDetail22';
import AccessoriesDetail23 from './Components/Turya/Turya-categorypages/Detailed-turya/AccessoriesDetail23';
import AccessoriesDetail24 from './Components/Turya/Turya-categorypages/Detailed-turya/AccessoriesDetail24';
import AccessoriesDetail25 from './Components/Turya/Turya-categorypages/Detailed-turya/AccessoriesDetail25';
import AccessoriesDetail26 from './Components/Turya/Turya-categorypages/Detailed-turya/AccessoriesDetail26';
import AccessoriesDetail27 from './Components/Turya/Turya-categorypages/Detailed-turya/AccessoriesDetail27';
import AccessoriesDetail28 from './Components/Turya/Turya-categorypages/Detailed-turya/AccessoriesDetail28';
import AccessoriesDetail29 from './Components/Turya/Turya-categorypages/Detailed-turya/AccessoriesDetail29';
import AccessoriesDetail30 from './Components/Turya/Turya-categorypages/Detailed-turya/AccessoriesDetail30';
import AccessoriesDetail31 from './Components/Turya/Turya-categorypages/Detailed-turya/AccessoriesDetail31';
import AccessoriesDetail32 from './Components/Turya/Turya-categorypages/Detailed-turya/AccessoriesDetail32';
import AccessoriesDetail33 from './Components/Turya/Turya-categorypages/Detailed-turya/AccessoriesDetail33';
import AccessoriesDetail34 from './Components/Turya/Turya-categorypages/Detailed-turya/AccessoriesDetail34';
import AccessoriesDetail35 from './Components/Turya/Turya-categorypages/Detailed-turya/AccessoriesDetail35';
import AccessoriesDetail36 from './Components/Turya/Turya-categorypages/Detailed-turya/AccessoriesDetail36';
import Drawer1 from './Components/Turya/Turya-categorypages/Detailed-turya/Drawer1';
import Drawer2 from './Components/Turya/Turya-categorypages/Detailed-turya/Drawer2';
import Drawer3 from './Components/Turya/Turya-categorypages/Detailed-turya/Drawer3';
import Drawer4 from './Components/Turya/Turya-categorypages/Detailed-turya/Drawer4';
import Drawer5 from './Components/Turya/Turya-categorypages/Detailed-turya/Drawer5';
import Drawer6 from './Components/Turya/Turya-categorypages/Detailed-turya/Drawer6';
import SeatingDetail2 from './Components/Turya/Turya-categorypages/Detailed-turya/SeatingDetail2';
import SeatingDetail3 from './Components/Turya/Turya-categorypages/Detailed-turya/SeatingDetail3';
import SeatingDetail4 from './Components/Turya/Turya-categorypages/Detailed-turya/SeatingDetail4';
import SeatingDetail5 from './Components/Turya/Turya-categorypages/Detailed-turya/SeatingDetail5';
import SeatingDetail6 from './Components/Turya/Turya-categorypages/Detailed-turya/SeatingDetail6';
import SeatingDetail7 from './Components/Turya/Turya-categorypages/Detailed-turya/SeatingDetail7';
import SeatingDetail8 from './Components/Turya/Turya-categorypages/Detailed-turya/SeatingDetail8';
import SeatingDetail30 from './Components/Turya/Turya-categorypages/Detailed-turya/SeatingDetail30';
import SeatingDetail31 from './Components/Turya/Turya-categorypages/Detailed-turya/SeatingDetail31';
import SeatingDetail32 from './Components/Turya/Turya-categorypages/Detailed-turya/SeatingDetail32';
import SeatingDetail33 from './Components/Turya/Turya-categorypages/Detailed-turya/SeatingDetail33';
import SeatingDetail34 from './Components/Turya/Turya-categorypages/Detailed-turya/SeatingDetail34';
import SeatingDetail35 from './Components/Turya/Turya-categorypages/Detailed-turya/SeatingDetail35';
import SeatingDetail36 from './Components/Turya/Turya-categorypages/Detailed-turya/SeatingDetail36';
import SeatingDetail37 from './Components/Turya/Turya-categorypages/Detailed-turya/SeatingDetail37';
import SeatingDetail38 from './Components/Turya/Turya-categorypages/Detailed-turya/SeatingDetail38';
import SeatingDetail9 from './Components/Turya/Turya-categorypages/Detailed-turya/SeatingDetail9';
import SeatingDetail10 from './Components/Turya/Turya-categorypages/Detailed-turya/SeatingDetail10';
import SeatingDetail11 from './Components/Turya/Turya-categorypages/Detailed-turya/SeatingDetail11';
import SeatingDetail12 from './Components/Turya/Turya-categorypages/Detailed-turya/SeatingDetail12';
import SeatingDetail13 from './Components/Turya/Turya-categorypages/Detailed-turya/SeatingDetail13';
import SeatingDetail14 from './Components/Turya/Turya-categorypages/Detailed-turya/SeatingDetail14';
import SeatingDetail15 from './Components/Turya/Turya-categorypages/Detailed-turya/SeatingDetail15';
import SeatingDetail16 from './Components/Turya/Turya-categorypages/Detailed-turya/SeatingDetail16';
import SeatingDetail17 from './Components/Turya/Turya-categorypages/Detailed-turya/SeatingDetail17';
import SeatingDetail18 from './Components/Turya/Turya-categorypages/Detailed-turya/SeatingDetail18';
import SeatingDetail19 from './Components/Turya/Turya-categorypages/Detailed-turya/SeatingDetail19';
import SeatingDetail20 from './Components/Turya/Turya-categorypages/Detailed-turya/SeatingDetail20';
import SeatingDetail21 from './Components/Turya/Turya-categorypages/Detailed-turya/SeatingDetail21';
import SeatingDetail22 from './Components/Turya/Turya-categorypages/Detailed-turya/SeatingDetail22';
import SeatingDetail23 from './Components/Turya/Turya-categorypages/Detailed-turya/SeatingDetail23';
import SeatingDetail24 from './Components/Turya/Turya-categorypages/Detailed-turya/SeatingDetail24';
import SeatingDetail25 from './Components/Turya/Turya-categorypages/Detailed-turya/SeatingDetail25';
import SeatingDetail26 from './Components/Turya/Turya-categorypages/Detailed-turya/SeatingDetail26';
import SeatingDetail27 from './Components/Turya/Turya-categorypages/Detailed-turya/SeatingDetail27';
import SeatingDetail28 from './Components/Turya/Turya-categorypages/Detailed-turya/SeatingDetail28';
import SeatingDetail29 from './Components/Turya/Turya-categorypages/Detailed-turya/SeatingDetail29';
import BedroomDetail1 from './Components/Turya/Turya-categorypages/Detailed-turya/BedroomDetail1';
import BedroomDetail2 from './Components/Turya/Turya-categorypages/Detailed-turya/BedroomDetail2';
import BedroomDetail3 from './Components/Turya/Turya-categorypages/Detailed-turya/BedroomDetail3';
import BedroomDetail4 from './Components/Turya/Turya-categorypages/Detailed-turya/BedroomDetail4';
import BedroomDetail5 from './Components/Turya/Turya-categorypages/Detailed-turya/BedroomDetail5';
import BedroomDetail6 from './Components/Turya/Turya-categorypages/Detailed-turya/BedroomDetail6';
import MirrorDetail13 from './Components/Turya/Turya-categorypages/Detailed-turya/MirrorDetail13';
import MirrorDetail14 from './Components/Turya/Turya-categorypages/Detailed-turya/MirrorDetail14';
import MirrorDetail15 from './Components/Turya/Turya-categorypages/Detailed-turya/MirrorDetail15';
import MirrorDetail16 from './Components/Turya/Turya-categorypages/Detailed-turya/MirrorDetail16';
import MirrorDetail17 from './Components/Turya/Turya-categorypages/Detailed-turya/MirrorDetail17';
import MirrorDetail18 from './Components/Turya/Turya-categorypages/Detailed-turya/MirrorDetail18';
import MirrorDetail19 from './Components/Turya/Turya-categorypages/Detailed-turya/MirrorDetail19';
import MirrorDetail20 from './Components/Turya/Turya-categorypages/Detailed-turya/MirrorDetail20';
import MirrorDetail21 from './Components/Turya/Turya-categorypages/Detailed-turya/MirrorDetail21';
import CentreTableDetail1 from './Components/Turya/Turya-categorypages/Detailed-turya/CentreTableDetail1';
import CentreTableDetail2 from './Components/Turya/Turya-categorypages/Detailed-turya/CentreTableDetail2';
import CentreTableDetail3 from './Components/Turya/Turya-categorypages/Detailed-turya/CentreTableDetail3';
import CentreTableDetail4 from './Components/Turya/Turya-categorypages/Detailed-turya/CentreTableDetail4';
import CentreTableDetail5 from './Components/Turya/Turya-categorypages/Detailed-turya/CentreTableDetail5';
import CentreTableDetail6 from './Components/Turya/Turya-categorypages/Detailed-turya/CentreTableDetail6';
import CentreTableDetail7 from './Components/Turya/Turya-categorypages/Detailed-turya/CentreTableDetail7';
import CentreTableDetail8 from './Components/Turya/Turya-categorypages/Detailed-turya/CentreTableDetail8';
import CentreTableDetail9 from './Components/Turya/Turya-categorypages/Detailed-turya/CentreTableDetail9';
import CentreTableDetail10 from './Components/Turya/Turya-categorypages/Detailed-turya/CentreTableDetail10';
import CentreTableDetail11 from './Components/Turya/Turya-categorypages/Detailed-turya/CentreTableDetail11';
import CentreTableDetail12 from './Components/Turya/Turya-categorypages/Detailed-turya/CentreTableDetail12';
import CentreTableDetail13 from './Components/Turya/Turya-categorypages/Detailed-turya/CentreTableDetail13';
import CentreTableDetail15 from './Components/Turya/Turya-categorypages/Detailed-turya/CentreTableDetail15';
import CentreTableDetail16 from './Components/Turya/Turya-categorypages/Detailed-turya/CentreTableDetail16';
import CentreTableDetail17 from './Components/Turya/Turya-categorypages/Detailed-turya/CentreTableDetail17';
import CentreTableDetail18 from './Components/Turya/Turya-categorypages/Detailed-turya/CentreTableDetail18';
import CentreTableDetail19 from './Components/Turya/Turya-categorypages/Detailed-turya/CentreTableDetail19';
import CentreTableDetail20 from './Components/Turya/Turya-categorypages/Detailed-turya/CentreTableDetail20';
import CentreTableDetail21 from './Components/Turya/Turya-categorypages/Detailed-turya/CentreTableDetail21';
import CentreTableDetail22 from './Components/Turya/Turya-categorypages/Detailed-turya/CentreTableDetail22';
import CentreTableDetail23 from './Components/Turya/Turya-categorypages/Detailed-turya/CentreTableDetail23';
import CentreTableDetail24 from './Components/Turya/Turya-categorypages/Detailed-turya/CentreTableDetail24';
import CentreTableDetail25 from './Components/Turya/Turya-categorypages/Detailed-turya/CentreTableDetail25';
import CentreTableDetail26 from './Components/Turya/Turya-categorypages/Detailed-turya/CentreTableDetail26';
import CentreTableDetail27 from './Components/Turya/Turya-categorypages/Detailed-turya/CentreTableDetail27';
import CentreTableDetail28 from './Components/Turya/Turya-categorypages/Detailed-turya/CentreTableDetail28';
import CentreTableDetail29 from './Components/Turya/Turya-categorypages/Detailed-turya/CentreTableDetail29';
import CentreTableDetail30 from './Components/Turya/Turya-categorypages/Detailed-turya/CentreTableDetail30';
import CentreTableDetail31 from './Components/Turya/Turya-categorypages/Detailed-turya/CentreTableDetail31';
import CentreTableDetail32 from './Components/Turya/Turya-categorypages/Detailed-turya/CentreTableDetail32';
import CentreTableDetail33 from './Components/Turya/Turya-categorypages/Detailed-turya/CentreTableDetail33';
import CentreTableDetail34 from './Components/Turya/Turya-categorypages/Detailed-turya/CentreTableDetail34';
import CentreTableDetail35 from './Components/Turya/Turya-categorypages/Detailed-turya/CentreTableDetail35';
import CentreTableDetail36 from './Components/Turya/Turya-categorypages/Detailed-turya/CentreTableDetail36';
import CentreTableDetail37 from './Components/Turya/Turya-categorypages/Detailed-turya/CentreTableDetail37';
import CentreTableDetail38 from './Components/Turya/Turya-categorypages/Detailed-turya/CentreTableDetail38';
import CentreTableDetail39 from './Components/Turya/Turya-categorypages/Detailed-turya/CentreTableDetail39';
import CentreTableDetail40 from './Components/Turya/Turya-categorypages/Detailed-turya/CentreTableDetail40';
import CentreTableDetail41 from './Components/Turya/Turya-categorypages/Detailed-turya/CentreTableDetail41';
import CentreTableDetail42 from './Components/Turya/Turya-categorypages/Detailed-turya/CentreTableDetail42';
import CentreTableDetail43 from './Components/Turya/Turya-categorypages/Detailed-turya/CentreTableDetail43';
import CentreTableDetail44 from './Components/Turya/Turya-categorypages/Detailed-turya/CentreTableDetail44';
import CentreTableDetail45 from './Components/Turya/Turya-categorypages/Detailed-turya/CentreTableDetail45';
import CentreTableDetail46 from './Components/Turya/Turya-categorypages/Detailed-turya/CentreTableDetail46';
import CentreTableDetail47 from './Components/Turya/Turya-categorypages/Detailed-turya/CentreTableDetail47';
import CentreTableDetail48 from './Components/Turya/Turya-categorypages/Detailed-turya/CentreTableDetail48';
import CentreTableDetail49 from './Components/Turya/Turya-categorypages/Detailed-turya/CentreTableDetail49';
import CentreTableDetail50 from './Components/Turya/Turya-categorypages/Detailed-turya/CentreTableDetail50';
import CentreTableDetail51 from './Components/Turya/Turya-categorypages/Detailed-turya/CentreTableDetail51';
import CentreTableDetail52 from './Components/Turya/Turya-categorypages/Detailed-turya/CentreTableDetail52';
import CentreTableDetail53 from './Components/Turya/Turya-categorypages/Detailed-turya/CentreTableDetail53';
import CentreTableDetail54 from './Components/Turya/Turya-categorypages/Detailed-turya/CentreTableDetail54';
import CentreTableDetail55 from './Components/Turya/Turya-categorypages/Detailed-turya/CentreTableDetail55';
import CentreTableDetail57 from './Components/Turya/Turya-categorypages/Detailed-turya/CentreTableDetail57';
import CentreTableDetail56 from './Components/Turya/Turya-categorypages/Detailed-turya/CentreTableDetail56';
import CentreTableDetail14 from './Components/Turya/Turya-categorypages/Detailed-turya/CentreTableDetail14';





const App = () => {
 

  return (

    <Router>
     
      <Routes>
      

        <Route path="/" element={<Turya />} /> 
     
        <Route path="/Turyaproduct" element={<Turyaproduct />} /> 
        <Route path="/Detailproduct" element={<Detailproduct />} /> 
     
        <Route path="/Seating" element={<Seating />} />
        <Route path="/Bedroom" element={<Bedroom />} />
        <Route path="/Mirrors" element={<Mirrors />} />
        <Route path="/Dress" element={<Dress />} />
        <Route path="/Sidetable" element={<Sidetable />} />
        <Route path="/Centretable" element={<Centretable />} />
        <Route path="/Dinningtable" element={<Dinningtable />} />
        <Route path="/Pa" element={<Pa />} />
        <Route path="/Drawer" element={<Drawer />} />
        <Route path="/Accessories" element={<Accessories />} />
        <Route path="/SeatingDetail1" element={<SeatingDetail1 />} />
        <Route path="/SeatingDetail2" element={<SeatingDetail2 />} />
        <Route path="/SeatingDetail3" element={<SeatingDetail3 />} />
        <Route path="/SeatingDetail4" element={<SeatingDetail4 />} />
        <Route path="/SeatingDetail5" element={<SeatingDetail5 />} />
        <Route path="/SeatingDetail6" element={<SeatingDetail6 />} />
        <Route path="/SeatingDetail7" element={<SeatingDetail7 />} />
        <Route path="/SeatingDetail8" element={<SeatingDetail8 />} />
        <Route path="/SeatingDetail9" element={<SeatingDetail9 />} />
        <Route path="/SeatingDetail10" element={<SeatingDetail10 />} />
        <Route path="/SeatingDetail11" element={<SeatingDetail11 />} />
        <Route path="/SeatingDetail12" element={<SeatingDetail12 />} />
        <Route path="/SeatingDetail13" element={<SeatingDetail13 />} />
        <Route path="/SeatingDetail14" element={<SeatingDetail14 />} />
        <Route path="/SeatingDetail15" element={<SeatingDetail15 />} />
        <Route path="/SeatingDetail16" element={<SeatingDetail16 />} />
        <Route path="/SeatingDetail17" element={<SeatingDetail17 />} />
        <Route path="/SeatingDetail18" element={<SeatingDetail18 />} />
        <Route path="/SeatingDetail19" element={<SeatingDetail19 />} />
        <Route path="/SeatingDetail20" element={<SeatingDetail20 />} />
        <Route path="/SeatingDetail21" element={<SeatingDetail21 />} />
        <Route path="/SeatingDetail22" element={<SeatingDetail22 />} />
        <Route path="/SeatingDetail23" element={<SeatingDetail23 />} />
        <Route path="/SeatingDetail24" element={<SeatingDetail24 />} />
        <Route path="/SeatingDetail25" element={<SeatingDetail25 />} />
        <Route path="/SeatingDetail26" element={<SeatingDetail26 />} />
        <Route path="/SeatingDetail27" element={<SeatingDetail27 />} />
        <Route path="/SeatingDetail28" element={<SeatingDetail28 />} />
        <Route path="/SeatingDetail29" element={<SeatingDetail29 />} />
        <Route path="/SeatingDetail30" element={<SeatingDetail30 />} />
        <Route path="/SeatingDetail31" element={<SeatingDetail31 />} />
        <Route path="/SeatingDetail32" element={<SeatingDetail32 />} />
        <Route path="/SeatingDetail33" element={<SeatingDetail33 />} />
        <Route path="/SeatingDetail34" element={<SeatingDetail34 />} />
        <Route path="/SeatingDetail35" element={<SeatingDetail35 />} />
        <Route path="/SeatingDetail36" element={<SeatingDetail36 />} />
        <Route path="/SeatingDetail37" element={<SeatingDetail37 />} />
        <Route path="/SeatingDetail38" element={<SeatingDetail38 />} />
        <Route path="/MirrorDetail1" element={<MirrorDetail1 />} />
        <Route path="/MirrorDetail2" element={<MirrorDetail2 />} />
        <Route path="/MirrorDetail3" element={<MirrorDetail3 />} />
        <Route path="/MirrorDetail4" element={<MirrorDetail4 />} />
        <Route path="/MirrorDetail5" element={<MirrorDetail5 />} />
        <Route path="/MirrorDetail6" element={<MirrorDetail6 />} />
        <Route path="/MirrorDetail7" element={<MirrorDetail7 />} />
        <Route path="/MirrorDetail8" element={<MirrorDetail8 />} />
        <Route path="/MirrorDetail9" element={<MirrorDetail9 />} />
        <Route path="/MirrorDetail10" element={<MirrorDetail10 />} />
        <Route path="/MirrorDetail11" element={<MirrorDetail11 />} />
        <Route path="/MirrorDetail12" element={<MirrorDetail12 />} />
        <Route path="/MirrorDetail13" element={<MirrorDetail13 />} />
        <Route path="/MirrorDetail14" element={<MirrorDetail14 />} />
        <Route path="/MirrorDetail15" element={<MirrorDetail15 />} />
        <Route path="/MirrorDetail16" element={<MirrorDetail16 />} />
        <Route path="/MirrorDetail17" element={<MirrorDetail17 />} />
        <Route path="/MirrorDetail18" element={<MirrorDetail18 />} />
        <Route path="/MirrorDetail19" element={<MirrorDetail19 />} />
        <Route path="/MirrorDetail20" element={<MirrorDetail20 />} />
        <Route path="/MirrorDetail21" element={<MirrorDetail21 />} />
        <Route path="/SidetableDetail1" element={<SidetableDetail1 />} />
        <Route path="/SidetableDetail2" element={<SidetableDetail2 />} />
        <Route path="/SidetableDetail3" element={<SidetableDetail3 />} />
        <Route path="/DinningtableDetail1" element={<DinningtableDetail1 />} />
        <Route path="/DinningtableDetail2" element={<DinningtableDetail2 />} />
        <Route path="/DinningtableDetail3" element={<DinningtableDetail3 />} />
        <Route path="/DinningtableDetail4" element={<DinningtableDetail4 />} />
        <Route path="/DinningtableDetail4" element={<DinningtableDetail4 />} />
        <Route path="/PaDetail1" element={<PaDetail1 />} />
        <Route path="/PaDetail2" element={<PaDetail2 />} />
        <Route path="/PaDetail3" element={<PaDetail3 />} />
        <Route path="/PaDetail4" element={<PaDetail4 />} />
        <Route path="/PaDetail5" element={<PaDetail5 />} />
        <Route path="/PaDetail6" element={<PaDetail6 />} />
        <Route path="/PaDetail7" element={<PaDetail7 />} />
        <Route path="/PaDetail8" element={<PaDetail8 />} />
        <Route path="/PaDetail9" element={<PaDetail9 />} />
        <Route path="/PaDetail10" element={<PaDetail10 />} />
        <Route path="/PaDetail11" element={<PaDetail11 />} />
        <Route path="/AccessoriesDetail1" element={<AccessoriesDetail1 />} />
        <Route path="/AccessoriesDetail2" element={<AccessoriesDetail2 />} />
        <Route path="/AccessoriesDetail3" element={<AccessoriesDetail3 />} />
        <Route path="/AccessoriesDetail4" element={<AccessoriesDetail4 />} />
        <Route path="/AccessoriesDetail5" element={<AccessoriesDetail5 />} />
        <Route path="/AccessoriesDetail6" element={<AccessoriesDetail6 />} />
        <Route path="/AccessoriesDetail7" element={<AccessoriesDetail7 />} />
        <Route path="/AccessoriesDetail8" element={<AccessoriesDetail8 />} />
        <Route path="/AccessoriesDetail9" element={<AccessoriesDetail9 />} />
        <Route path="/AccessoriesDetail10" element={<AccessoriesDetail10 />} />
        <Route path="/AccessoriesDetail11" element={<AccessoriesDetail11 />} />
        <Route path="/AccessoriesDetail12" element={<AccessoriesDetail12 />} />
        <Route path="/AccessoriesDetail13" element={<AccessoriesDetail13 />} />
        <Route path="/AccessoriesDetail14" element={<AccessoriesDetail14 />} />
        <Route path="/AccessoriesDetail15" element={<AccessoriesDetail15 />} />
        <Route path="/AccessoriesDetail16" element={<AccessoriesDetail16 />} />
        <Route path="/AccessoriesDetail17" element={<AccessoriesDetail17 />} />
        <Route path="/AccessoriesDetail18" element={<AccessoriesDetail18 />} />
        <Route path="/AccessoriesDetail19" element={<AccessoriesDetail19 />} />
        <Route path="/AccessoriesDetail20" element={<AccessoriesDetail20 />} />
        <Route path="/AccessoriesDetail21" element={<AccessoriesDetail21 />} />
        <Route path="/AccessoriesDetail22" element={<AccessoriesDetail22 />} />
        <Route path="/AccessoriesDetail23" element={<AccessoriesDetail23 />} />
        <Route path="/AccessoriesDetail24" element={<AccessoriesDetail24 />} />
        <Route path="/AccessoriesDetail25" element={<AccessoriesDetail25 />} />
        <Route path="/AccessoriesDetail26" element={<AccessoriesDetail26 />} />
        <Route path="/AccessoriesDetail27" element={<AccessoriesDetail27 />} />
        <Route path="/AccessoriesDetail28" element={<AccessoriesDetail28 />} />
        <Route path="/AccessoriesDetail29" element={<AccessoriesDetail29 />} />
        <Route path="/AccessoriesDetail30" element={<AccessoriesDetail30 />} />
        <Route path="/AccessoriesDetail31" element={<AccessoriesDetail31 />} />
        <Route path="/AccessoriesDetail32" element={<AccessoriesDetail32 />} />
        <Route path="/AccessoriesDetail33" element={<AccessoriesDetail33 />} />
        <Route path="/AccessoriesDetail34" element={<AccessoriesDetail34 />} />
        <Route path="/AccessoriesDetail35" element={<AccessoriesDetail35 />} />
        <Route path="/AccessoriesDetail36" element={<AccessoriesDetail36 />} />
        <Route path="/Drawer1" element={<Drawer1 />} />
        <Route path="/Drawer2" element={<Drawer2 />} />
        <Route path="/Drawer3" element={<Drawer3 />} />
        <Route path="/Drawer4" element={<Drawer4 />} />
        <Route path="/Drawer5" element={<Drawer5 />} />
        <Route path="/Drawer6" element={<Drawer6 />} />
        <Route path="/Bedroom1" element={<BedroomDetail1 />} />
        <Route path="/Bedroom2" element={<BedroomDetail2 />} />
        <Route path="/Bedroom3" element={<BedroomDetail3 />} />
        <Route path="/Bedroom4" element={<BedroomDetail4 />} />
        <Route path="/Bedroom5" element={<BedroomDetail5 />} />
        <Route path="/Bedroom6" element={<BedroomDetail6 />} />
        <Route path="/CentreTabledetail1" element={<CentreTableDetail1 />} />
        <Route path="/CentreTabledetail2" element={<CentreTableDetail2 />} />
        <Route path="/CentreTabledetail3" element={<CentreTableDetail3 />} />
        <Route path="/CentreTabledetail4" element={<CentreTableDetail4 />} />
        <Route path="/CentreTabledetail5" element={<CentreTableDetail5 />} />
        <Route path="/CentreTabledetail6" element={<CentreTableDetail6 />} />
        <Route path="/CentreTabledetail7" element={<CentreTableDetail7 />} />
        <Route path="/CentreTabledetail8" element={<CentreTableDetail8 />} />
        <Route path="/CentreTabledetail9" element={<CentreTableDetail9 />} />
        <Route path="/CentreTabledetail10" element={<CentreTableDetail10 />} />
        <Route path="/CentreTabledetail11" element={<CentreTableDetail11 />} />
        <Route path="/CentreTabledetail12" element={<CentreTableDetail12 />} />
        <Route path="/CentreTabledetail13" element={<CentreTableDetail13 />} />
        <Route path="/CentreTabledetail14" element={<CentreTableDetail14 />} />
        <Route path="/CentreTabledetail15" element={<CentreTableDetail15 />} />
        <Route path="/CentreTabledetail16" element={<CentreTableDetail16 />} />
        <Route path="/CentreTabledetail17" element={<CentreTableDetail17 />} />
        <Route path="/CentreTabledetail18" element={<CentreTableDetail18 />} />
        <Route path="/CentreTabledetail19" element={<CentreTableDetail19 />} />
        <Route path="/CentreTabledetail20" element={<CentreTableDetail20 />} />
        <Route path="/CentreTabledetail21" element={<CentreTableDetail21 />} />
        <Route path="/CentreTabledetail22" element={<CentreTableDetail22 />} />
        <Route path="/CentreTabledetail23" element={<CentreTableDetail23 />} />
        <Route path="/CentreTabledetail24" element={<CentreTableDetail24 />} />
        <Route path="/CentreTabledetail25" element={<CentreTableDetail25 />} />
        <Route path="/CentreTabledetail26" element={<CentreTableDetail26 />} />
        <Route path="/CentreTabledetail27" element={<CentreTableDetail27 />} />
        <Route path="/CentreTabledetail28" element={<CentreTableDetail28 />} />
        <Route path="/CentreTabledetail29" element={<CentreTableDetail29 />} />
        <Route path="/CentreTabledetail30" element={<CentreTableDetail30 />} />
        <Route path="/CentreTabledetail31" element={<CentreTableDetail31 />} />
        <Route path="/CentreTabledetail32" element={<CentreTableDetail32 />} />
        <Route path="/CentreTabledetail33" element={<CentreTableDetail33 />} />
        <Route path="/CentreTabledetail34" element={<CentreTableDetail34 />} />
        <Route path="/CentreTabledetail35" element={<CentreTableDetail35 />} />
        <Route path="/CentreTabledetail36" element={<CentreTableDetail36 />} />
        <Route path="/CentreTabledetail37" element={<CentreTableDetail37 />} />
        <Route path="/CentreTabledetail38" element={<CentreTableDetail38 />} />
        <Route path="/CentreTabledetail39" element={<CentreTableDetail39 />} />
        <Route path="/CentreTabledetail40" element={<CentreTableDetail40 />} />
        <Route path="/CentreTabledetail41" element={<CentreTableDetail41 />} />
        <Route path="/CentreTabledetail42" element={<CentreTableDetail42 />} />
        <Route path="/CentreTabledetail43" element={<CentreTableDetail43 />} />
        <Route path="/CentreTabledetail44" element={<CentreTableDetail44 />} />
        <Route path="/CentreTabledetail45" element={<CentreTableDetail45 />} />
        <Route path="/CentreTabledetail46" element={<CentreTableDetail46 />} />
        <Route path="/CentreTabledetail47" element={<CentreTableDetail47 />} />
        <Route path="/CentreTabledetail48" element={<CentreTableDetail48 />} />
        <Route path="/CentreTabledetail49" element={<CentreTableDetail49 />} />
        <Route path="/CentreTabledetail50" element={<CentreTableDetail50 />} />
        <Route path="/CentreTabledetail51" element={<CentreTableDetail51 />} />
        <Route path="/CentreTabledetail52" element={<CentreTableDetail52 />} />
        <Route path="/CentreTabledetail53" element={<CentreTableDetail53 />} />
        <Route path="/CentreTabledetail54" element={<CentreTableDetail54 />} />
        <Route path="/CentreTabledetail55" element={<CentreTableDetail55 />} />
        <Route path="/CentreTabledetail56" element={<CentreTableDetail56 />} />
        <Route path="/CentreTabledetail57" element={<CentreTableDetail57 />} />
        
      
       

      

      </Routes>

    </Router>

  
  );
};

export default App;

